<script>
    import _map from 'lodash/map';
    import { jewels } from '@/services';
    import abstractForm from './abstract-form.vue';

    const fields = [
        { name: 'group', type: 'select', label: 'Вид изделия', required: true },
        { name: 'type', type: 'select', label: 'Тип изделия', required: true },
        { name: 'metal', type: 'select', label: 'Металл', required: true },
        { name: 'standard', type: 'select', label: 'Проба', required: true },
        { name: 'comment', type: 'textarea', label: 'Описание' },
        { name: 'stamp_type', type: 'select', label: 'Тип именника', required: true },
        { name: 'stamp_symbol', type: 'symbol', label: 'Символы именника' },
        { name: 'indent_type', type: 'select', label: 'Тип клейма', required: true },
        { name: 'indent_symbol', type: 'text', label: 'Шифр инспекции' },
        { name: 'is_approved', type: 'boolean', label: 'Клеймо подлинное' }
    ];

    const fieldsets = [
        { names: ['group', 'type'] },
        { names: ['metal', 'standard'] },
        { names: ['comment'] },
        { names: ['stamp_type', 'stamp_symbol'] },
        { names: ['indent_type', 'indent_symbol'] },
        { names: ['is_approved'] }
    ];

    const checks = {
        group: { required: true },
        type: { required: true },
        metal: { required: true },
        standard: { required: true },
        stamp_type: { required: true },
        stamp_symbol: { required: false },
        indent_type: { required: true },
        indent_symbol: { required: false }
    };

    export default {
        extends: abstractForm,
        data() {
            return {
                fields,
                fieldsets,
                checks,
                options: {
                    group: undefined,
                    type: undefined,
                    metal: undefined,
                    standard: undefined,
                    stamp_type: undefined,
                    indent_type: undefined
                },
                inline: true
            };
        },
        watch: {
            'val.group': function(value) {
                if (value) {
                    this.$store.dispatch('showLoader', { label: 'Загрузка типов изделий' });

                    jewels.getTypes({ group_code: value }).then((items) => {
                        this.options.type = _map(items, (el) => {
                            return { name: el.name, value: el.code };
                        });
                    }).catch((error) => {
                        const err = new Error('Не удалось загрузить типы изделий');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }).finally(() => {
                        this.$store.dispatch('hideLoader');
                    });
                } else {
                    this.options.type = [];
                }
            },
            'val.metal': function(value) {
                if (value) {
                    this.$store.dispatch('showLoader', { label: 'Загрузка проб' });

                    jewels.getStandards({ metal_code: value }).then((items) => {
                        this.options.standard = _map(items, (el) => {
                            return { name: el.name, value: el.code };
                        });
                    }).catch((error) => {
                        const err = new Error('Не удалось загрузить пробы');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }).finally(() => {
                        this.$store.dispatch('hideLoader');
                    });
                } else {
                    this.options.standard = [];
                }
            },
            'val.stamp_type': function(value, old) {
                if (value && value !== 'none') {
                    this.checks.stamp_symbol.required = true;
                    this.fieldRel.stamp_symbol.required = true;
                    this.fieldRel.stamp_symbol.readonly = false;
                } else {
                    this.checks.stamp_symbol.required = false;
                    this.fieldRel.stamp_symbol.required = false;
                    this.fieldRel.stamp_symbol.readonly = true;

                    if (old !== undefined) {
                        this.val.stamp_symbol = '';
                    }
                }
            },
            'val.indent_type': function(value, old) {
                if (value && value !== 'none') {
                    this.checks.indent_symbol.required = true;
                    this.fieldRel.indent_symbol.required = true;
                    this.fieldRel.indent_symbol.readonly = false;
                } else {
                    this.checks.indent_symbol.required = false;
                    this.fieldRel.indent_symbol.required = false;
                    this.fieldRel.indent_symbol.readonly = true;

                    if (old !== undefined) {
                        this.val.indent_symbol = '';
                    }
                }
            }
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Загрузка вариантов' });

            let promises = [
                jewels.getGroups(),
                jewels.getMetals(),
                jewels.getStampTypes(),
                jewels.getIndentTypes()
            ];

            Promise.all(promises).then((values) => {
                this.options.group = _map(values[0], (el) => {
                    return { name: el.name, value: el.code };
                });
                this.options.metal = _map(values[1], (el) => {
                    return { name: el.name, value: el.code };
                });
                this.options.stamp_type = _map(values[2], (el) => {
                    return { name: el.name, value: el.code };
                });
                this.options.indent_type = _map(values[3], (el) => {
                    return { name: el.name, value: el.code };
                });
            }).catch((error) => {
                const err = new Error('Не удалось загрузить варианты');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            }).finally(() => {
                this.$store.dispatch('hideLoader');
            });
        }
    };
</script>
