<template>
    <div class="main__block">
        <info
            v-if="isStage('info')"
            :item="item"
            @prev="prevInfo"
            @next="nextInfo"
            @builder="showBuilder"
        />
        <builder
            v-if="isStage('builder')"
            :item="item"
            @prev="prevBuilder"
            @next="nextBuilder"
        />
        <prop
            v-if="isStage('prop')"
            :item="item"
            @prev="prevProp"
            @next="nextProp"
        />
        <inserts
            v-if="isStage('inserts')"
            :item="item"
            @prev="prevInserts"
            @next="nextInserts"
        />
        <descr
            v-if="isStage('descr')"
            :item="item"
            @prev="prevDescr"
            @next="nextDescr"
        />
        <confirm
            v-if="isStage('confirm')"
            :item="item"
            @prev="prevConfirm"
            @next="nextConfirm"
            @goto="goTo"
        />
    </div>
</template>

<script>
    import _defaults from 'lodash/defaults';
    import info from './info.vue';
    import builder from './builder.vue';
    import prop from './prop.vue';
    import inserts from './inserts.vue';
    import descr from './descr.vue';
    import confirm from './confirm.vue';

    export default {
        components: {
            info,
            builder,
            prop,
            inserts,
            descr,
            confirm
        },
        props: {
            values: {
                type: Object,
                default() { return {
                    info: undefined,
                    inserts: [],
                    prop: undefined,
                    descr: undefined
                }; }
            }
        },
        data() {
            return {
                stage: 'info',
                info: this.values.info,
                inserts: this.values.inserts,
                prop: this.values.prop,
                descr: this.values.descr
            };
        },
        computed: {
            item() {
                return {
                    info: this.info,
                    inserts: this.inserts,
                    prop: this.prop,
                    descr: this.descr
                };
            },
            insertsAvail() {
                return this.info && this.info._inserts_avail;
            }
        },
        methods: {
            isStage(stage) {
                return this.stage === stage;
            },
            goTo(stage) {
                this.stage = stage;
            },
            serialize() {
                return this.item;
            },
            prev() {
                this.$emit('prev', this.serialize());
            },
            next() {
                this.$emit('next', this.serialize());
            },
            prevInfo(params) {
                this.info = params;
                this.prev();
            },
            nextInfo(params) {
                this.info = params;
                this.goTo('prop');
            },
            showBuilder(params) {
                this.info = params;
                this.goTo('builder');
            },
            prevBuilder(params) {
                this.goTo('info');
            },
            nextBuilder(params) {
                this.info = _defaults({}, params, this.info);
                this.goTo('info');
            },
            prevProp(params) {
                this.prop = params;
                this.goTo('info');
            },
            nextProp(params) {
                this.prop = params;
                if (this.insertsAvail) {
                    this.goTo('inserts');
                } else {
                    this.goTo('descr');
                }
            },
            prevInserts(items) {
                this.inserts = items;
                this.goTo('prop');
            },
            nextInserts(items) {
                this.inserts = items;
                this.goTo('descr');
            },
            prevDescr(params) {
                this.descr = params;
                if (this.insertsAvail) {
                    this.goTo('inserts');
                } else {
                    this.goTo('prop');
                }
            },
            nextDescr(params) {
                this.descr = params;
                this.goTo('confirm');
            },
            prevConfirm() {
                this.goTo('descr');
            },
            nextConfirm() {
                this.next();
            }
        }
    };
</script>
