<script>
    import _find from 'lodash/find';
    import _map from 'lodash/map';
    import _filter from 'lodash/filter';
    import _includes from 'lodash/includes';
    import { jewels } from '@/services';
    import abstractForm from './abstract-form.vue';

    const fields = [
        { name: 'insert', type: 'select', label: 'Тип вставки', required: true },
        { name: 'cut', type: 'select', label: 'Огранка вставки', required: true },
        { name: 'height', type: 'number', label: 'Высота, мм', precision: 1, required: true },
        { name: 'length', type: 'number', label: 'Длина, мм', precision: 1, required: true },
        { name: 'width', type: 'number', label: 'Ширина, мм', precision: 1, required: true },
        { name: 'width_avg', type: 'number', label: 'Средняя ширина, мм', precision: 1, required: true },
        { name: 'diameter', type: 'number', label: 'Диаметр, мм', precision: 1, required: true },
        { name: 'weight', type: 'number', label: 'Вес вставки, гр.', precision: 2, readonly: true },
        { name: 'count', type: 'number', label: 'Количество', default: 1 },
        { name: 'comment', type: 'textarea', label: 'Описание' }
    ];

    const fieldsets = [
        { names: ['insert', 'cut'] },
        { names: ['height', 'length', 'width', 'width_avg', 'diameter'] },
        { names: ['weight', 'count', 'comment'] }
    ];

    const sizes = ['height', 'length', 'width', 'width_avg', 'diameter'];
    const sizeRe = /([a-z_]+)/;

    const checks = {
        insert: { required: true },
        cut: { required: true },
        weight: { required: true, number: true },
        height: { number: true },
        length: { number: true },
        width: { number: true },
        width_avg: { number: true },
        diameter: { number: true },
        count: { required: true, number: true }
    };

    export default {
        extends: abstractForm,
        props: {
            type: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                fields,
                fieldsets,
                checks,
                options: {
                    insert: undefined,
                    cut: undefined
                },
                inline: true
            };
        },
        computed: {
            density() {
                if (this.opt.insert && this.val.insert) {
                    let item = _find(this.opt.insert, { value: this.val.insert });
                    return item ? item.density : undefined;
                }
                return undefined;
            },
            volumeFormula() {
                if (this.opt.cut && this.val.cut) {
                    let item = _find(this.opt.cut, { value: this.val.cut });
                    return item ? item.formula : undefined;
                }
                return undefined;
            },
            volumeAttrs() {
                let attrs = {};
                if (this.volumeFormula) {
                    let items = this.volumeFormula.split(sizeRe);
                    for (let item of items) {
                        if (sizeRe.test(item)) {
                            attrs[ item ] = true;
                        }
                    }
                }
                return attrs;
            },
            volume() {
                if (this.volumeFormula) {
                    for (let size in this.volumeAttrs) {
                        if (!this.val[ size ]) {
                            return undefined;
                        }
                    }
                    let items = this.volumeFormula.split(sizeRe),
                        exprs = [];
                    for (let item of items) {
                        if (sizeRe.test(item)) {
                            item = 'this.val.' + item;
                        }
                        exprs.push(item);
                    }
                    let expr = exprs.join(''),
                        volume = undefined;

                    try {
                        volume = eval(expr);
                    } catch (error) {
                        const err = new Error('Не удалось вычислить объем');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }

                    return volume;
                }
                return undefined;
            }
        },
        watch: {
            'val.insert': function(value, old) {
                if (value) {
                    this.$store.dispatch('showLoader', { label: 'Загрузка огранок' });

                    jewels.getCuts({ insert_code: value }).then((items) => {
                        this.options.cut = _map(items, (el) => {
                            return { name: el.name, value: el.code, formula: el.formula };
                        });
                        this.showAttrs();
                    }).catch((error) => {
                        const err = new Error('Не удалось загрузить огранки');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }).finally(() => {
                        this.$store.dispatch('hideLoader');
                    });
                } else if (old !== undefined) {
                    this.options.cut = [];
                    this.showAttrs();
                }

                if (old !== undefined) {
                    this.calcWeight();
                }
            },
            'val.cut': function(value, old) {
                this.showAttrs();

                if (old !== undefined) {
                    this.calcWeight();
                }
            }
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Загрузка вставок' });

            jewels.getInserts({ type_code: this.type }).then((items) => {
                this.options.insert = _map(items, (el) => {
                    return { name: el.name, value: el.code, density: el.density };
                });
                this.options.cut = [];

                if (!this.val.insert) {
                    this.showAttrs();
                }
            }).catch((error) => {
                const err = new Error('Не удалось загрузить вставки');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            }).finally(() => {
                this.$store.dispatch('hideLoader');
            });

            for (let fieldName of sizes) {
                this.$watch('val.' + fieldName, (value, old) => {
                    if (old !== undefined) {
                        this.calcWeight();
                    }
                });
            }
        },
        methods: {
            showAttrs() {
                if (this.val.insert !== undefined && this.val.cut !== undefined && this.opt.insert !== undefined && this.opt.cut !== undefined) {
                    this.fields = _filter(fields, (el) => {
                        return !_includes(sizes, el.name) || this.volumeAttrs[ el.name ];
                    });

                    for (let size of sizes) {
                        if (this.volumeAttrs[size]) {
                            this.checks[size].required = true;
                        } else {
                            this.val[size] = '';
                            this.checks[size].required = false;
                        }
                    }
                }
            },
            calcWeight() {
                if (this.volume !== undefined && this.density !== undefined) {
                    this.val.weight = this.roundWeight(this.volume * this.density);
                } else {
                    this.val.weight = '';
                }
            },
            roundWeight(value) {
                return value.toFixed(2);
            }
        }
    };
</script>
