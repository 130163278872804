<script>
    import _map from 'lodash/map';
    import { jewels } from '@/services';
    import abstractForm from './abstract-form.vue';

    const fields = [
        { name: 'metal', type: 'select', label: 'Металл', required: true },
        { name: 'standard', type: 'select', label: 'Проба', required: true },
        { name: 'stamp', type: 'icon', label: 'Форма именника', required: true },
        { name: 'stamp_symbol', type: 'symbol', label: 'Символы именника', required: true },
        { name: 'indent', type: 'icon', label: 'Форма пробирного клейма', required: true },
        { name: 'mark', type: 'icon', label: 'Знак удостоверения',  required: true },
        { name: 'indent_symbol', type: 'text', label: 'Шифр инспекции', required: true },
        { name: 'position', type: 'icon', label: 'Расположение пробирного клейма относительно именника', required: true }
    ];

    const fieldsets = [
        { names: ['metal', 'standard'] },
        { names: ['stamp', 'stamp_symbol'] },
        { names: ['indent', 'mark', 'indent_symbol'] },
        { names: ['position'] }
    ];

    const checks = {
        metal: { required: true },
        standard: { required: true },
        stamp: { required: true },
        stamp_symbol: { required: true },
        indent: { required: true },
        mark: { required: true },
        indent_symbol: { required: true },
        position: { required: true }
    };

    export default {
        extends: abstractForm,
        data() {
            return {
                fields,
                fieldsets,
                checks,
                options: {
                    metal: undefined,
                    standard: [],
                    stamp: undefined,
                    indent: undefined,
                    mark: undefined,
                    position: undefined
                },
                inline: true
            };
        },
        watch: {
            'val.metal': function(value, old) {
                if (old !== undefined) {
                    this.val.standard = '';
                    this.options.standard = [];
                }

                if (value) {
                    this.$store.dispatch('showLoader', { label: 'Загрузка проб' });

                    jewels.getStandards({ metal_code: value }).then((items) => {
                        this.options.standard = _map(items, (el) => { return { name: el.name, value: el.code }; });
                    }).catch((error) => {
                        const err = new Error('Не удалось загрузить пробы');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }).finally(() => {
                        this.$store.dispatch('hideLoader');
                    });
                }
            }
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Загрузка вариантов' });

            let promises = [
                jewels.getMetals(),
                jewels.getStamps(),
                jewels.getIndents(),
                jewels.getMarks(),
                jewels.getPositions()
            ];

            Promise.all(promises).then((values) => {
                this.options.metal = _map(values[0], (el) => { return { name: el.name, value: el.code }; });
                this.options.stamp = _map(values[1], (el) => { return { name: el.name, value: el.code, src: el.image }; });
                this.options.indent = _map(values[2], (el) => { return { name: el.name, value: el.code, src: el.image }; });
                this.options.mark = _map(values[3], (el) => { return { name: el.name, value: el.code, src: el.image }; });
                this.options.position = _map(values[4], (el) => { return { name: el.name, value: el.code, src: el.image }; });
            }).catch((error) => {
                const err = new Error('Не удалось загрузить варианты');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            }).finally(() => {
                this.$store.dispatch('hideLoader');
            });
        }
    };
</script>
