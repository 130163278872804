<template>
    <div class="scheme-select">
        <div
            v-if="items.length"
            class="scheme-select__list"
        >
            <div
                v-for="item in items"
                class="scheme-select__item"
                :class="{ 'is-active': isActive(item) }"
                @click="select(item)"
            >
                <div class="scheme-select__header">
                    {{ item.name }}
                </div>
                <div class="scheme-select__attrs">
                    <div class="scheme-select__attr">
                        <span class="scheme-select__attr-label">
                            Срок кредитования:
                        </span>
                        <span class="scheme-select__attr-value">
                            {{ item.duration }}
                        </span>
                    </div>
                    <div class="scheme-select__attr">
                        <span class="scheme-select__attr-label">
                            Кредитная ставка:
                        </span>
                        <span class="scheme-select__attr-value">
                            {{ item.percent }}
                        </span>
                    </div>
                    <div class="scheme-select__attr">
                        <span class="scheme-select__attr-label">
                            Дата выкупа:
                        </span>
                        <span class="scheme-select__attr-value">
                            {{ item.ransom_date | date('DD.MM.YYYY') }}
                        </span>
                    </div>
                    <div class="scheme-select__attr">
                        <span class="scheme-select__attr-label">
                            Дата реализации:
                        </span>
                        <span class="scheme-select__attr-value">
                            {{ item.sell_date | date('DD.MM.YYYY') }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            class="scheme-select__empty"
        >
            <slot name="empty">
                Список пуст
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            items: {
                type: Array,
                default() { return []; }
            }
        },
        methods: {
            isActive(item) {
                return this.value === item.id;
            },
            select(item) {
                let value = this.isActive(item) ? '' : item.id;
                this.$emit('change', value);
            }
        }
    };
</script>
