<template>
    <div class="main__block">
        <div class="main__body">
            <h2 class="h2">
                Опишите внешний вид
            </h2>
            <jewel-form
                ref="form"
                :values="item.info"
                class="main__section"
                @change="checkValid"
            />
            <div class="main__section field field--type_button field--inline">
                <label class="field__label">
                    Проверка подлинности
                </label>
                <div class="field__input">
                    <button
                        :disabled="!builderIsEnabled"
                        class="inpton inpton--purple"
                        @click="builder"
                    >
                        Открыть конструктор
                    </button>
                </div>
            </div>
        </div>
        <div class="main__foot main__bar">
            <button
                class="main__cancel button button--white"
                @click="prev"
            >
                Вернуться назад
            </button>
            <button
                :disabled="!isValid"
                class="main__submit button button--green"
                @click="next"
            >
                Продолжить заполнение
            </button>
        </div>
    </div>
</template>

<script>
    import _defaults from 'lodash/defaults';
    import _find from 'lodash/find';
    import { jewels } from '@/services';
    import jewelForm from '@/components/jewel-form.vue';

    export default {
        components: {
            jewelForm
        },
        props: {
            item: Object
        },
        data() {
            return {
                types: [],
                builderIsEnabled: false,
                isValid: false
            };
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Загрузка типов изделий' });

            jewels.getTypes().then((items) => {
                this.types = items;
            }).catch((error) => {
                const err = new Error('Не удалось загрузить типы изделий');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            }).finally(() => {
                this.$store.dispatch('hideLoader');
            });
        },
        mounted() {
            this.checkValid();
        },
        methods: {
            checkValid() {
                let params = this.$refs.form.serialize();
                this.builderIsEnabled = ((params.stamp_type === 'factory' || params.stamp_type === 'master') && params.indent_type === 'domestic');
                this.isValid = this.$refs.form.validate();
            },
            insertsAvail(type) {
                let item = _find(this.types, { code: type });
                return !!(item && item.inserts.length);
            },
            serialize() {
                let params = this.$refs.form.serialize();
                return _defaults({ _inserts_avail: this.insertsAvail(params.type) }, params);
            },
            prev() {
                this.$emit('prev', this.serialize());
            },
            next() {
                this.$emit('next', this.serialize());
            },
            builder() {
                this.$emit('builder', this.serialize());
            }
        }
    };
</script>
