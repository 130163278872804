<template>
    <div class="pawn-select">
        <div
            v-if="items.length"
            class="pawn-select__list"
        >
            <div
                v-for="item in items"
                class="pawn-select__item"
                :class="{ 'is-active': isActive(item) }"
                @click="select(item)"
            >
                <div
                    class="pawn-select__icon"
                    :class="'pawn-select__icon--' + item.icon"
                />
                <div class="pawn-select__name">
                    {{ item.name }}
                </div>
            </div>
        </div>
        <div
            v-else
            class="pawn-select__empty"
        >
            <slot name="empty">
                Список пуст
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            items: {
                type: Array,
                default() { return []; }
            }
        },
        methods: {
            isActive(item) {
                return this.value === item.code;
            },
            select(item) {
                let value = this.isActive(item) ? '' : item.code;
                this.$emit('change', value);
            }
        }
    };
</script>
