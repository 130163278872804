<template>
    <div class="main__block">
        <div class="main__body">
            <h2 class="h2">
                Выберите схему кредитования
            </h2>
            <scheme-select
                v-model="value"
                :items="items"
            />
        </div>

        <div class="main__foot main__bar">
            <button
                class="button button--white main__cancel"
                @click="prev"
            >
                Вернуться назад
            </button>
            <button
                class="button button--green main__submit"
                :disabled="!isValid"
                @click="next"
            >
                Продолжить заполнение
            </button>
        </div>
    </div>
</template>

<script>
    import { pawns } from '@/services';
    import schemeSelect from '@/components/scheme-select.vue';

    export default {
        components: {
            schemeSelect
        },
        props: {
            item: Object
        },
        data() {
            return {
                value: this.item.scheme,
                items: []
            };
        },
        computed: {
            isValid() {
                return !!this.value;
            }
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Загрузка схем' });

            pawns.getSchemes({
                type: this.item.type,
                client_id: this.item.client.id
            }).then((items) => {
                this.items = items;
            }).catch((error) => {
                const err = new Error('Не удалось загрузить схемы');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            }).finally(() => {
                this.$store.dispatch('hideLoader');
            });
        },
        methods: {
            prev() {
                this.$emit('prev', this.value);
            },
            next() {
                this.$emit('next', this.value);
            }
        }
    };
</script>
