<template>
    <main class="main">
        <template v-if="isStage('search')">
            <div class="main__head">
                <h1 class="h1">
                    Поиск залогодателя
                </h1>
            </div>
            <search
                :controls="controls"
                @prev="prevClients"
                @create="nextClients"
                @control:pawn="nextClients"
            />
        </template>
        <template v-if="isStage('client')">
            <div class="main__head">
                <h1 class="h1">
                    {{ client ? 'Проверка клиента' : 'Добавление клиента' }}
                </h1>
            </div>
            <client
                :values="client"
                @prev="prevClient"
                @next="nextClient"
            />
        </template>
        <template v-if="isStage('verify')">
            <div class="main__head">
                <h1 class="h1">
                    Верификация клиента
                </h1>
            </div>
            <verify
                :values="client"
                @prev="prevVerify"
                @next="nextVerify"
            />
        </template>
        <template v-if="isStage('pawn')">
            <div class="main__head">
                <h1 class="h1">
                    Добавление нового залога
                </h1>
            </div>
            <pawn
                :client="client"
                :values="pawn"
                @prev="prevPawn"
                @next="nextPawn"
            />
        </template>

        <modal
            :is-shown="isSuccess"
            @hide="next"
        >
            <h3
                slot="header"
                class="modal__header h2"
            >
                Залог добавлен
            </h3>
            <div
                slot="content"
                class="modal__content text"
            >
                <p>Операция залога успешно создана</p>
                <p>Нажмите OK для перехода к главному меню</p>
            </div>
            <div
                slot="footer"
                class="modal__panel"
            >
                <button
                    class="modal__submit button button--green"
                    @click="next"
                >
                    OK
                </button>
            </div>
        </modal>
    </main>
</template>

<script>
    import { clients, pawns } from '@/services';
    import search from '@/views/operations/clients/main.vue';
    import client from '@/views/operations/client/main.vue';
    import verify from '@/views/operations/verify/main.vue';
    import pawn from '@/views/operations/pawn/main.vue';
    import modal from '@/components/modal.vue';

    export default {
        components: {
            search,
            client,
            verify,
            pawn,
            modal
        },
        data() {
            return {
                isSuccess: false,
                stage: 'search',
                controls: [
                    { event: 'pawn', label: 'Оформить залог', color: 'green' }
                ],
                client: undefined,
                pawn: undefined
            };
        },
        created() {
            let clientId = this.$router.currentRoute.query.client_id;
            if (clientId) {
                let client = this.$store.state.client;
                if (client && client.id === clientId) {
                    this.client = client;
                    this.stage = 'client';
                } else {
                    delete this.$store.state.client;

                    this.$store.dispatch('showLoader', { label: 'Загрузка клиента' });

                    clients.getClient(clientId).then((item) => {
                        this.client = item;
                        this.stage = 'client';
                    }).catch((error) => {
                        const err = new Error('Не удалось загрузить клиента');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }).finally(() => {
                        this.$store.dispatch('hideLoader');
                    });
                }
            } else {
                this.stage = 'search';
            }
        },
        methods: {
            isStage(stage) {
                return this.stage === stage;
            },
            goTo(stage) {
                this.stage = stage;
            },
            prev(data) {
                this.$router.go(-1);
            },
            next() {
                this.$router.push({ name: 'index' });
            },
            prevClients() {
                this.prev();
            },
            nextClients(client) {
                if (client) {
                    this.client = client;
                    this.$store.state.client = client;
                    this.$router.push({ name: 'pawn-create', query: { client_id: client.id } });
                } else {
                    this.client = undefined;
                }
                this.goTo('client');
            },
            prevClient(client) {
                this.client = client;
                this.$router.push({ name: 'pawn-create' });
                this.goTo('search');
            },
            nextClient(client) {
                if (!this.client) {
                    this.$store.dispatch('showLoader', { label: 'Добавление клиента' });

                    clients.createClient(client).then((data) => {
                        this.client = data;
                        this.$store.state.client = data;
                        this.$router.push({ name: 'pawn-create', query: { client_id: data.id } });

                        if (this.client.state.is_verified) {
                            this.goTo('pawn');
                        } else {
                            this.goTo('verify');
                        }
                    }).catch((error) => {
                        const err = new Error('Не удалось добавить клиента');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }).finally(() => {
                        this.$store.dispatch('hideLoader');
                    });
                } else {
                    this.$store.dispatch('showLoader', { label: 'Обновление клиента' });

                    clients.updateClient(this.client.id, client).then((data) => {
                        this.client = data;

                        if (this.client.state.is_verified) {
                            this.goTo('pawn');
                        } else {
                            this.goTo('verify');
                        }
                    }).catch((error) => {
                        const err = new Error('Не удалось обновить клиента');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }).finally(() => {
                        this.$store.dispatch('hideLoader');
                    });
                }
            },
            prevVerify(isVerified) {
                this.client.state.is_verified = isVerified;
                this.goTo('client');
            },
            nextVerify(isVerified) {
                this.client.state.is_verified = isVerified;
                this.goTo('pawn');
            },
            prevPawn(pawn) {
                this.pawn = pawn;

                if (this.client.state.is_verified) {
                    this.goTo('client');
                } else {
                    this.goTo('verify');
                }
            },
            nextPawn(pawn) {
                this.$store.dispatch('showLoader', { label: 'Добавление залога' });

                pawns.createPawn(pawn).then((data) => {
                    this.pawn = data;
                    this.isSuccess = true;
                    delete this.$store.state.client;
                }).catch((error) => {
                    const err = new Error('Не удалось добавить залог');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            }
        }
    };
</script>
