<template>
    <div class="main__block">
        <div class="main__body">
            <h2 class="h2">
                Проверьте подлинность клейм
            </h2>
            <builder-form
                ref="form"
                :values="item.info"
                @change="checkValid"
            />
        </div>

        <div class="main__foot main__bar">
            <button
                class="main__cancel button button--white"
                @click="prev"
            >
                Отменить
            </button>
            <button
                :disabled="!isValid"
                class="main__submit button button--green"
                @click="check"
            >
                Проверить
            </button>
        </div>

        <modal
            :is-shown="!!result"
            @hide="hide"
        >
            <template v-if="result">
                <template v-if="result.is_approved">
                    <h3
                        slot="header"
                        class="modal__header h2"
                    >
                        Признаков подделки не обнаружено
                    </h3>
                    <div
                        slot="content"
                        class="modal__content text"
                    >
                        <p>Изделие успешно проверено</p>
                    </div>
                    <div
                        slot="footer"
                        class="modal__panel"
                    >
                        <button
                            class="modal__prev button button--white"
                            @click="hide"
                        >
                            Назад
                        </button>
                        <button
                            class="modal__next button button--green"
                            @click="next"
                        >
                            Далее
                        </button>
                    </div>
                </template>
                <template v-else>
                    <h3
                        slot="header"
                        class="modal__header h2"
                    >
                        Обнаружены признаки подделки!
                    </h3>
                    <div
                        slot="content"
                        class="modal__content text"
                    >
                        <p>При проверке обнаружены несоответствия</p>
                        <p v-if="result.detail">
                            {{ result.detail }}
                        </p>
                    </div>
                    <div
                        slot="footer"
                        class="modal__panel"
                    >
                        <button
                            class="modal__prev button button--white"
                            @click="hide"
                        >
                            Назад
                        </button>
                        <button
                            class="modal__next button button--red"
                            @click="next"
                        >
                            Далее
                        </button>
                    </div>
                </template>
            </template>
        </modal>
    </div>
</template>

<script>
    import _defaults from 'lodash/defaults';
    import { jewels } from '@/services';
    import builderForm from '@/components/builder-form.vue';
    import modal from '@/components/modal.vue';

    export default {
        components: {
            builderForm,
            modal
        },
        props: {
            item: Object
        },
        data() {
            return {
                isValid: false,
                result: undefined
            };
        },
        methods: {
            checkValid() {
                this.isValid = this.$refs.form.validate();
            },
            serialize() {
                return _defaults({
                    is_approved: this.result && this.result.is_approved
                }, this.$refs.form.serialize());
            },
            check() {
                this.$store.dispatch('showLoader', { label: 'Идет проверка клейм' });
                this.result = undefined;

                jewels.checkJewel(this.$refs.form.serialize()).then((data) => {
                    this.result = data
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.detail) {
                        this.result = {
                            is_approved: false,
                            detail: error.response.data.detail
                        };
                    } else {
                        const err = new Error('Не удалось проверить клейма');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            hide() {
                this.result = undefined;
            },
            prev() {
                this.$emit('prev', this.serialize());
            },
            next() {
                this.$emit('next', this.serialize());
            }
        }
    };
</script>
