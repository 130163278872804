<template>
    <div class="main__block">
        <div class="main__body">
            <h2 class="h2">
                Заполните анкету
            </h2>
            <custom-form
                v-if="settings.fields.length"
                ref="form"
                :settings="settings"
                :values="item.questionnaire"
                @change="checkValid"
            />
        </div>

        <div class="main__foot main__bar">
            <button
                class="button button--white main__cancel"
                @click="prev"
            >
                Вернуться назад
            </button>
            <button
                class="button button--green main__submit"
                :disabled="!isValid"
                @click="next"
            >
                Продолжить заполнение
            </button>
        </div>
    </div>
</template>

<script>
    import { pawns } from '@/services';
    import customForm from '@/components/custom-form.vue';

    export default {
        components: {
            customForm
        },
        props: {
            item: Object
        },
        data() {
            return {
                isValid: false,
                settings: {
                    fields: [],
                    checks: {},
                    options: {}
                }
            };
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Загрузка анкеты' });

            pawns.getQuestions({
                type: this.item.type,
                client_id: this.item.client.id
            }).then((items) => {
                let fields = [];
                for (let item of items) {
                    let field = {
                        name: item.id,
                        label: item.name,
                        type: item.type,
                        required: item.required,
                        checks: {}
                    };

                    if (item.options) {
                        field.options = item.options;
                    }
                    if (item.required) {
                        field.checks.required = true;
                    }

                    fields.push(field);
                }
                this.settings = {
                    fields
                };
            }).catch((error) => {
                const err = new Error('Не удалось загрузить анкету');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            }).finally(() => {
                this.$store.dispatch('hideLoader');
            });
        },
        methods: {
            checkValid() {
                this.isValid = this.$refs.form.validate();
            },
            prev() {
                this.$emit('prev', this.$refs.form.serialize());
            },
            next() {
                this.$emit('next', this.$refs.form.serialize());
            }
        }
    };
</script>
