<template>
    <div class="main__block">
        <check
            v-if="isStage('check')"
            :item="item"
            @next="nextCheck"
            @prev="prevCheck"
        />
        <type
            v-if="isStage('type')"
            :item="item"
            @next="nextType"
            @prev="prevType"
        />
        <jewels
            v-if="isStage('jewels')"
            :item="item"
            @next="nextJewels"
            @prev="prevJewels"
        />
        <questionnaire
            v-if="isStage('questionnaire')"
            :item="item"
            @next="nextQuestionnaire"
            @prev="prevQuestionnaire"
        />
        <scheme
            v-if="isStage('scheme')"
            :item="item"
            @next="nextScheme"
            @prev="prevScheme"
        />
        <money
            v-if="isStage('money')"
            :item="item"
            @next="nextMoney"
            @prev="prevMoney"
        />
        <confirm
            v-if="isStage('confirm')"
            :item="item"
            @next="nextConfirm"
            @prev="prevConfirm"
            @goto="goTo"
        />
    </div>
</template>

<script>
    import _reduce from 'lodash/reduce';
    import check from './check.vue';
    import type from './type.vue';
    import jewels from './jewels.vue';
    import questionnaire from './questionnaire.vue';
    import scheme from './scheme.vue';
    import money from './money.vue';
    import confirm from './confirm.vue';

    export default {
        components: {
            check,
            type,
            jewels,
            questionnaire,
            scheme,
            money,
            confirm
        },
        props: {
            client: {
                type: Object,
                required: true
            },
            values: {
                type: Object,
                default() {
                    return {
                        type: undefined,
                        items: [],
                        questionnaire: undefined,
                        scheme: undefined,
                        money: undefined
                    };
                }
            }
        },
        data() {
            return {
                stage: 'check',
                type: this.values.type,
                items: this.values.items || [],
                questionnaire: this.values.questionnaire,
                scheme: this.values.scheme,
                money: this.values.money
            };
        },
        computed: {
            item() {
                return {
                    client: this.client,
                    type: this.type,
                    items: this.items,
                    questionnaire: this.questionnaire,
                    scheme: this.scheme,
                    money: this.money
                };
            }
        },
        methods: {
            isStage(stage) {
                return this.stage === stage;
            },
            goTo(stage) {
                this.stage = stage;
            },
            serialize() {
                return this.item;
            },
            getEvalSum() {
                return _reduce(this.items, (sum, item) => {
                    return sum + parseFloat(item.descr.price);
                }, 0);
            },
            prev() {
                this.$emit('prev', this.serialize());
            },
            next() {
                this.$emit('next', this.serialize());
            },
            reset() {
                this.items = [];
                this.questionnaire = undefined;
                this.scheme = undefined;
                this.money = undefined;
            },
            prevCheck() {
                this.prev();
            },
            nextCheck() {
                this.goTo('type');
            },
            prevType(type) {
                if (type !== this.type) {
                    this.reset();
                }
                this.type = type;
                this.goTo('check');
            },
            nextType(type) {
                this.type = type;
                if (this.type === 'jewel') {
                    this.goTo('jewels');
                } else {
                    const err = new Error(`Неизвестный тип залога: ${this.type}`);
                    this.$store.dispatch('showError', { err, vm: this });
                }
            },
            prevJewels(items) {
                this.items = items;
                this.goTo('type');
            },
            nextJewels(items) {
                this.items = items;
                this.goTo('questionnaire');
            },
            prevQuestionnaire(questionnaire) {
                this.questionnaire = questionnaire;
                this.goTo('jewels');
            },
            nextQuestionnaire(questionnaire) {
                this.questionnaire = questionnaire;
                this.goTo('scheme');
            },
            prevScheme(scheme) {
                this.scheme = scheme;
                this.goTo('questionnaire');
            },
            nextScheme(scheme) {
                this.scheme = scheme;
                this.goTo('money');
            },
            prevMoney(money) {
                this.money = money;
                this.goTo('scheme');
            },
            nextMoney(money) {
                this.money = money;
                this.goTo('confirm');
            },
            prevConfirm() {
                this.goTo('money');
            },
            nextConfirm() {
                this.next();
            }
        }
    };
</script>
