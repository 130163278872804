<template>
    <div class="main__block">
        <div class="main__body">
            <h2 class="h2">
                Укажите результаты экспертной оценки
            </h2>
            <descr-form
                ref="form"
                :values="item.descr"
                :insert-weight="insertWeight"
                @change="checkValid"
            />
        </div>

        <div class="main__foot main__bar">
            <button
                class="main__cancel button button--white"
                @click="prev"
            >
                Вернуться назад
            </button>
            <button
                :disabled="!isValid"
                class="main__submit button button--green"
                @click="check"
            >
                Оценить изделие
            </button>
        </div>

        <modal
            :is-shown="!!result"
            @hide="hide"
        >
            <template v-if="result">
                <h3
                    slot="header"
                    class="modal__header h2"
                >
                    Изделие было успешно оценено
                </h3>
                <div
                    slot="content"
                    class="modal__content text"
                >
                    <h4>Оценка: {{ result.price | price }}</h4>
                    <h4>Категория: {{ result.category.name }}</h4>
                </div>
                <div
                    slot="footer"
                    class="modal__panel"
                >
                    <button
                        class="modal__prev button button--white"
                        @click="hide"
                    >
                        Назад
                    </button>
                    <button
                        class="modal__next button button--green"
                        @click="next"
                    >
                        Далее
                    </button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
    import _defaults from 'lodash/defaults';
    import _reduce from 'lodash/reduce';
    import { jewels } from '@/services';
    import descrForm from '@/components/descr-form.vue';
    import modal from '@/components/modal.vue';

    export default {
        components: {
            descrForm,
            modal
        },
        props: {
            item: Object
        },
        data() {
            return {
                result: undefined,
                isValid: false
            };
        },
        computed: {
            insertWeight() {
                let weight = _reduce(this.item.inserts, (sum, item) => {
                    return sum + item.weight * item.count;
                }, 0);
                return weight;
            }
        },
        mounted() {
            this.checkValid();
        },
        methods: {
            checkValid() {
                this.isValid = this.$refs.form.validate();
            },
            serialize() {
                return _defaults({
                    price: this.result && this.result.price,
                    category: this.result && this.result.category && this.result.category.code
                }, this.$refs.form.serialize());
            },
            check() {
                this.$store.dispatch('showLoader', { label: 'Идет оценка изделия' });
                this.result = undefined;

                let descr = this.$refs.form.serialize();

                jewels.evalJewel({
                    type: this.item.info.type,
                    attrs: this.item.prop.attrs,
                    defects: this.item.prop.defects,
                    metal: descr.metal,
                    standard: descr.standard,
                    weight: descr.weight
                }, this.item.inserts).then((data) => {
                    this.result = data;
                }).catch((error) => {
                    const err = new Error('Не удалось оценить изделие');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            hide() {
                this.result = undefined;
            },
            prev() {
                this.$emit('prev', this.serialize());
            },
            next() {
                this.$emit('next', this.serialize());
            }
        }
    };
</script>
