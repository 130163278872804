<template>
    <div class="main__block">
        <template v-if="isStage('main')">
            <div class="main__body">
                <h2 class="h2">
                    Укажите список изделий
                </h2>
                <jewel-list
                    :items="items"
                    :controls="controls"
                    @control:update="showItem"
                    @control:delete="deleteItem"
                />
            </div>

            <div class="main__foot main__bar">
                <button
                    class="main__prev button button--white"
                    @click="prev"
                >
                    Вернуться назад
                </button>
                <button
                    :disabled="isFull"
                    class="main__this button button--yellow"
                    @click="showItem(-1)"
                >
                    Добавить изделие
                </button>
                <button
                    :disabled="!isValid"
                    class="main__next button button--green"
                    @click="next"
                >
                    Продолжить заполнение
                </button>
            </div>
        </template>
        <template v-else-if="isStage('item')">
            <jewel
                ref="item"
                :values="currentItem"
                @next="submitItem"
                @prev="showMain"
            />
        </template>
    </div>
</template>

<script>
    import jewelList from '@/components/jewel-list.vue';
    import jewel from '@/views/operations/jewel/main.vue';

    export default {
        components: {
            jewelList,
            jewel
        },
        props: {
            item: Object
        },
        data() {
            return {
                stage: 'main',
                items: this.item.items,
                idx: -1,
                controls: [
                    { event: 'update', label: 'Редактировать', color: 'yellow' },
                    { event: 'delete', label: 'Удалить', color: 'red' }
                ]
            };
        },
        computed: {
            isValid() {
                return !!this.items.length;
            },
            isFull() {
                return this.items.length >= 4;
            },
            currentItem() {
                return (this.idx !== -1) ? this.items[ this.idx ] : undefined;
            }
        },
        methods: {
            isStage(stage) {
                return this.stage === stage;
            },
            showMain() {
                this.stage = 'main';
                this.idx = -1;
            },
            showItem(idx) {
                this.idx = idx;
                this.stage = 'item';
            },
            submitItem(params) {
                params.controllable = true;

                if (this.idx !== -1) {
                    this.items[ this.idx ] = params;
                } else {
                    this.items.push(params);
                }

                this.showMain();
            },
            deleteItem(idx) {
                this.items.splice(idx, 1);
                this.showMain();
            },
            prev() {
                this.$emit('prev', this.items);
            },
            next() {
                this.$emit('next', this.items);
            }
        }
    };
</script>
