<template>
    <div class="main__block">
        <div class="main__body">
            <h2 class="h2">
                Проверка клиента по спискам
            </h2>

            <template v-if="result">
                <div class="main__section">
                    <h3 class="h3">
                        Результат проверки
                    </h3>
                    <div class="text">
                        <template v-if="result.is_accepted">
                            <template v-if="result.is_secure">
                                Проверка успешно пройдена, клиент допущен к займу
                            </template>
                            <template v-else>
                                <p>Клиент допущен к займу, но признан ненадежным</p>
                            </template>
                        </template>
                        <template v-else>
                            <p>Клиент не допущен к займу</p>
                        </template>
                        <p v-if="result.detail">
                            {{ result.detail }}
                        </p>
                    </div>
                </div>
            </template>
        </div>

        <div class="main__foot main__bar">
            <button
                class="button button--white main__cancel"
                @click="prev"
            >
                Вернуться назад
            </button>
            <button
                class="button button--green main__submit"
                :disabled="!isValid"
                @click="next"
            >
                Продолжить заполнение
            </button>
        </div>
    </div>
</template>

<script>
    import { clients } from '@/services';

    export default {
        props: {
            item: Object
        },
        data() {
            return {
                result: undefined
            };
        },
        computed: {
            isValid() {
                return this.result && this.result.is_accepted;
            }
        },
        created() {
            this.check();
        },
        methods: {
            prev() {
                this.$emit('prev');
            },
            next() {
                this.$emit('next');
            },
            check() {
                this.$store.dispatch('showLoader', { label: 'Проверка клиента' });
                this.result = undefined;

                clients.checkClient(this.item.client.id).then((data) => {
                    this.result = data;
                }).catch((error) => {
                    const err = new Error('Не удалось проверить клиента');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            }
        }
    };
</script>
