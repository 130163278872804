<template>
    <div class="main__block">
        <template v-if="isStage('main')">
            <div class="main__body">
                <h2 class="h2">
                    Укажите список вставок
                </h2>
                <insert-list
                    :items="items"
                    :controls="controls"
                    @control:update="showItem"
                    @control:delete="deleteItem"
                />
            </div>

            <div class="main__foot main__bar">
                <button
                    class="main__prev button button--white"
                    @click="prev"
                >
                    Вернуться назад
                </button>
                <button
                    class="main__this button button--yellow"
                    @click="showItem(-1)"
                >
                    Добавить вставку
                </button>
                <button
                    :disabled="!isValid"
                    class="main__next button button--green"
                    @click="next"
                >
                    Продолжить заполнение
                </button>
            </div>
        </template>
        <template v-else-if="isStage('item')">
            <div class="main__body">
                <h2 class="h2">
                    {{ currentItem ? 'Измените вставку' : 'Добавьте вставку' }}
                </h2>
                <insert-form
                    ref="item"
                    :values="currentItem"
                    :type="item.info.type"
                    @change="checkItemValid"
                />
            </div>

            <div class="main__foot main__bar">
                <button
                    class="main__cancel button button--white"
                    @click="showMain"
                >
                    Отменить
                </button>
                <button
                    :disabled="!itemIsValid"
                    class="main__submit button button--green"
                    @click="submitItem"
                >
                    {{ currentItem ? 'Внести изменения' : 'Добавить' }}
                </button>
            </div>
        </template>
    </div>
</template>

<script>
    import insertList from '@/components/insert-list.vue';
    import insertForm from '@/components/insert-form.vue';

    export default {
        components: {
            insertList,
            insertForm
        },
        props: {
            item: Object
        },
        data() {
            return {
                stage: 'main',
                items: this.item.inserts,
                itemIsValid: false,
                idx: -1,
                controls: [
                    { event: 'update', label: 'Редактировать', color: 'yellow' },
                    { event: 'delete', label: 'Удалить', color: 'red' }
                ]
            };
        },
        computed: {
            isValid() {
                return true;
            },
            currentItem() {
                return (this.idx !== -1) ? this.items[ this.idx ] : undefined;
            }
        },
        methods: {
            isStage(stage) {
                return this.stage === stage;
            },
            showMain() {
                this.stage = 'main';
                this.idx = -1;
            },
            showItem(idx) {
                this.idx = idx;
                this.stage = 'item';

                this.$nextTick(() => {
                    this.checkItemValid();
                });
            },
            submitItem() {
                let data = this.$refs.item.serialize();
                data.controllable = true;

                if (this.idx !== -1) {
                    this.items[ this.idx ] = data;
                } else {
                    this.items.push(data);
                }

                this.showMain();
            },
            deleteItem(idx) {
                this.items.splice(idx, 1);
                this.showMain();
            },
            checkItemValid() {
                this.itemIsValid = this.$refs.item.validate();
            },
            prev() {
                this.$emit('prev', this.items);
            },
            next() {
                this.$emit('next', this.items);
            }
        }
    };
</script>
