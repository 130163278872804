<template>
    <div class="main__block">
        <div class="main__body">
            <h2 class="h2">
                Укажите итоговую сумму займа
            </h2>
            <money-form
                v-if="pawnSum !== undefined"
                ref="form"
                :values="item.money"
                :pawn-sum="pawnSum"
                @change="checkValid"
            />
        </div>

        <div class="main__foot main__bar">
            <button
                class="button button--white main__cancel"
                @click="prev"
            >
                Вернуться назад
            </button>
            <button
                class="button button--green main__submit"
                :disabled="!isValid"
                @click="next"
            >
                Продолжить заполнение
            </button>
        </div>
    </div>
</template>

<script>
    import { pawns } from '@/services';
    import moneyForm from '@/components/money-form.vue';

    export default {
        components: {
            moneyForm
        },
        props: {
            item: Object
        },
        data() {
            return {
                isValid: false,
                pawnSum: undefined
            };
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Загрузка суммы займа' });

            pawns.getPawnSum({
                client_id: this.item.client.id,
                type: this.item.type,
                items: this.item.items,
                questionnaire: this.item.questionnaire,
                scheme: this.item.scheme
            }).then((data) => {
                this.pawnSum = data.sum;
                this.$nextTick(() => {
                    this.checkValid();
                });
            }).catch((error) => {
                const err = new Error('Не удалось получить доступную сумму займа');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            }).finally(() => {
                this.$store.dispatch('hideLoader');
            });
        },
        methods: {
            checkValid() {
                this.isValid = this.$refs.form && this.$refs.form.validate();
            },
            prev() {
                this.$emit('prev', this.$refs.form.serialize());
            },
            next() {
                this.$emit('next', this.$refs.form.serialize());
            }
        }
    };
</script>
