<template>
    <div class="entity-list">
        <div
            v-if="items.length"
            class="entity-list__list"
        >
            <div
                v-for="(item, idx) in items"
                class="entity-list__item entity-item"
            >
                <div class="entity-item__head entity-item__header">
                    {{ getInsert(item.insert) }}
                </div>

                <div class="entity-item__body entity-item__main">
                    <div
                        v-if="item.cut"
                        class="entity-item__attr"
                    >
                        <span class="entity-item__attr-label">
                            Огранка:
                        </span>
                        <span class="entity-item__attr-value">
                            {{ getCut(item.cut) }}
                        </span>
                    </div>
                    <div
                        v-if="item.weight"
                        class="entity-item__attr"
                    >
                        <span class="entity-item__attr-label">
                            Вес:
                        </span>
                        <span class="entity-item__attr-value">
                            {{ item.weight }} гр.
                        </span>
                    </div>
                    <div
                        v-if="item.count"
                        class="entity-item__attr"
                    >
                        <span class="entity-item__attr-label">
                            Количество:
                        </span>
                        <span class="entity-item__attr-value">
                            {{ item.count }}
                        </span>
                    </div>
                    <div
                        v-if="item.comment"
                        class="entity-item__attr"
                    >
                        <span class="entity-item__attr-label">
                            Описание:
                        </span>
                        <span class="entity-item__attr-value">
                            {{ item.comment }}
                        </span>
                    </div>
                </div>
                <div
                    v-if="item.controllable && controls.length"
                    class="entity-item__foot entity-item__panel"
                >
                    <div
                        v-for="control in controls"
                        class="entity-item__panel-item"
                    >
                        <a
                            href="#"
                            class="a"
                            :class="control.color ? 'a--' + control.color : ''"
                            @click.prevent="callControl(control.event, idx)"
                        >
                            {{ control.label }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            class="entity-list__empty"
        >
            <slot name="empty">
                Список пуст
            </slot>
        </div>
    </div>
</template>

<script>
    import _find from 'lodash/find';
    import { jewels } from '@/services';

    export default {
        props: {
            items: {
                type: Array,
                default() { return []; }
            },
            controls: {
                type: Array,
                default() { return []; }
            }
        },
        data() {
            return {
                inserts: [],
                cuts: []
            };
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Загрузка вариантов' });

            let promises = [
                jewels.getInserts(),
                jewels.getCuts()
            ];

            Promise.all(promises).then((values) => {
                this.inserts = values[0];
                this.cuts = values[1];
            }).catch((error) => {
                const err = new Error('Не удалось загрузить варианты');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            }).finally(() => {
                this.$store.dispatch('hideLoader');
            });
        },
        methods: {
            getInsert(code) {
                let item = _find(this.inserts, { code });
                return item ? item.name : '';
            },
            getCut(code) {
                let item = _find(this.cuts, { code });
                return item ? item.name : '';
            },
            callControl(event, idx) {
                this.$emit('control', event, idx);
                this.$emit('control:' + event, idx);
            }
        }
    };
</script>
