<script>
    import abstractInfo from './abstract-info.vue';

    export default {
        extends: abstractInfo,
        props: {
            settings: {
                type: Object,
                required: true
            }
        },
        data() {
            let data = {};
            for (let k in this.settings) {
                data[k] = this.settings[k];
            }
            return data;
        },
        watch: {
            settings: {
                handler(value) {
                    for (let k in value) {
                        this[k] = value[k];
                    }
                },
                deep: true
            }
        }
    };
</script>
