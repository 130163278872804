<script>
    import _map from 'lodash/map';
    import { jewels } from '@/services';
    import abstractForm from './abstract-form.vue';

    const fields = [
        { name: 'metal', type: 'select', label: 'Металл', required: true },
        { name: 'standard', type: 'select', label: 'Проба', required: true },
        { name: 'total_weight', type: 'number', precision: 2, label: 'Общий вес изделия, гр.', required: true },
        { name: 'weight', type: 'number', precision: 2, label: 'Чистый вес изделия, гр.', required: true, readonly: true }
    ];

    const checks = {
        metal: { required: true },
        standard: { required: true },
        total_weight: { required: true, number: true },
        weight: { required: true, number: true }
    };

    export default {
        extends: abstractForm,
        props: {
            insertWeight: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                fields,
                checks,
                options: {
                    metal: undefined,
                    standard: undefined
                },
                inline: true
            };
        },
        watch: {
            'val.metal': function(value) {
                if (value) {
                    this.$store.dispatch('showLoader', { label: 'Загрузка проб' });

                    jewels.getStandards({ metal_code: value }).then((items) => {
                        this.options.standard = _map(items, (el) => {
                            return { name: el.name, value: el.code };
                        });
                    }).catch((error) => {
                        const err = new Error('Не удалось загрузить пробы');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }).finally(() => {
                        this.$store.dispatch('hideLoader');
                    });
                } else {
                    this.options.standard = [];
                }
            },
            'val.total_weight': function(value) {
                if (value !== '' && value !== undefined) {
                    this.val.weight = value - this.insertWeight;
                } else {
                    this.val.weight = '';
                }
            }
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Загрузка металлов' });

            jewels.getMetals().then((items) => {
                this.options.metal = _map(items, (el) => {
                    return { name: el.name, value: el.code };
                });
            }).catch((error) => {
                const err = new Error('Не удалось загрузить металлы');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            }).finally(() => {
                this.$store.dispatch('hideLoader');
            });
        }
    };
</script>
