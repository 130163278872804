<template>
    <div class="main__block">
        <div class="main__body">
            <h2 class="h2">
                Проверьте данные
            </h2>

            <div class="main__section">
                <h3 class="h3">
                    Предметы залога
                    <a
                        href="#"
                        class="main__goto"
                        @click.prevent="goTo('jewels')"
                    />
                </h3>
                <jewel-list
                    v-if="item.type === 'jewel'"
                    :items="item.items"
                />
            </div>

            <div class="main__section">
                <h3 class="h3">
                    Анкета
                    <a
                        href="#"
                        class="main__goto"
                        @click.prevent="goTo('questionnaire')"
                    />
                </h3>
                <custom-info
                    :settings="settings"
                    :values="item.questionnaire"
                />
            </div>

            <div
                v-if="scheme"
                class="main__section"
            >
                <h3 class="h3">
                    Условия кредитования
                    <a
                        href="#"
                        class="main__goto"
                        @click.prevent="goTo('scheme')"
                    />
                </h3>

                <div class="info">
                    <value
                        type="text"
                        :inline="true"
                        label="Схема кредитования"
                        :value="scheme.name"
                        class="info__field"
                    />
                    <value
                        type="number"
                        :inline="true"
                        label="Срок кредита"
                        :value="scheme.duration"
                        class="info__field"
                    >
                        <template slot="value">
                            {{ scheme.duration }}
                        </template>
                    </value>
                    <value
                        type="number"
                        :inline="true"
                        label="Кредитная ставка"
                        :value="scheme.percent"
                        class="info__field"
                    >
                        <template slot="value">
                            {{ scheme.percent }}
                        </template>
                    </value>
                    <value
                        type="date"
                        :inline="true"
                        label="Дата выкупа"
                        :value="scheme.ransom_date"
                        class="info__field"
                    />
                    <value
                        type="date"
                        :inline="true"
                        label="Дата реализации"
                        :value="scheme.sell_date"
                        class="info__field"
                    />
                </div>
            </div>

            <div class="main__section">
                <h3 class="h3">
                    Сумма к выдаче
                    <a
                        href="#"
                        class="main__goto"
                        @click.prevent="goTo('money')"
                    />
                </h3>

                <div class="info">
                    <value
                        type="number"
                        :inline="true"
                        label="Сумма к выдаче, руб."
                        :value="item.money.credit_sum"
                        class="info__field"
                    />
                    <value
                        type="number"
                        :inline="true"
                        label="Выдать наличными, руб."
                        :value="item.money.cash_sum"
                        class="info__field"
                    />
                    <value
                        v-if="item.money.card_sum"
                        type="number"
                        :inline="true"
                        label="Выдать на карту, руб."
                        :value="item.money.card_sum"
                        class="info__field"
                    />
                    <value
                        v-if="item.money.card_sum"
                        type="text"
                        :inline="true"
                        label="Номер карты"
                        :value="item.money.card_number"
                        class="info__field"
                    />
                </div>
            </div>
        </div>

        <div class="main__foot main__bar">
            <button
                class="button button--white main__cancel"
                @click="prev"
            >
                Вернуться назад
            </button>
            <button
                class="button button--green main__submit"
                @click="next"
            >
                Продолжить заполнение
            </button>
        </div>
    </div>
</template>

<script>
    import _find from 'lodash/find';
    import _map from 'lodash/map';
    import { pawns } from '@/services';
    import jewelList from '@/components/jewel-list.vue';
    import customInfo from '@/components/custom-info.vue';
    import value from '@/components/value.vue';

    export default {
        components: {
            jewelList,
            customInfo,
            value
        },
        props: {
            item: Object
        },
        data() {
            return {
                schemes: [],
                settings: {
                    fields: []
                }
            };
        },
        computed: {
            scheme() {
                return _find(this.schemes, { id: this.item.scheme });
            }
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Загрузка вариантов' });

            let query = {
                type: this.item.type,
                client_id: this.item.client.id
            };

            let promises = [
                pawns.getQuestions(query),
                pawns.getSchemes(query)
            ];

            Promise.all(promises).then((values) => {
                this.settings = {
                    fields: _map(values[0], (el) => { return {
                        name: el.id,
                        label: el.name,
                        type: el.type,
                        options: el.options
                    }; })
                };
                this.schemes = values[1];
            }).catch((error) => {
                const err = new Error('Не удалось загрузить варианты');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            }).finally(() => {
                this.$store.dispatch('hideLoader');
            });
        },
        methods: {
            goTo(stage) {
                this.$emit('goto', stage);
            },
            prev() {
                this.$emit('prev');
            },
            next() {
                this.$emit('next');
            }
        }
    };
</script>
