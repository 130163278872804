<template>
    <div class="entity-list">
        <div
            v-if="items.length"
            class="entity-list__list"
        >
            <div
                v-for="(item, idx) in items"
                class="entity-list__item entity-item"
            >
                <div class="entity-item__head entity-item__header">
                    {{ getType(item.info.type) }}
                </div>

                <div class="entity-item__body entity-item__main">
                    <div class="entity-item__section">
                        <div
                            v-if="item.descr.metal"
                            class="entity-item__attr"
                        >
                            <span class="entity-item__attr-label">
                                Материал:
                            </span>
                            <span class="entity-item__attr-value">
                                {{ getMetal(item.descr.metal) }}
                            </span>
                        </div>
                        <div
                            v-if="item.descr.standard"
                            class="entity-item__attr"
                        >
                            <span class="entity-item__attr-label">
                                Проба:
                            </span>
                            <span class="entity-item__attr-value">
                                {{ getStandard(item.descr.metal, item.descr.standard) }}
                            </span>
                        </div>
                        <div
                            v-if="item.descr.weight"
                            class="entity-item__attr"
                        >
                            <span class="entity-item__attr-label">
                                Чистый вес:
                            </span>
                            <span class="entity-item__attr-value">
                                {{ item.descr.weight }} гр.
                            </span>
                        </div>
                        <div
                            v-if="item.info.comment"
                            class="entity-item__attr"
                        >
                            <span class="entity-item__attr-label">
                                Описание:
                            </span>
                            <span class="entity-item__attr-value">
                                {{ item.info.comment }}
                            </span>
                        </div>
                    </div>
                    <div class="entity-item__section">
                        <div
                            v-if="item.descr.category"
                            class="entity-item__attr"
                        >
                            <span class="entity-item__attr-label">
                                Категория:
                            </span>
                            <span class="entity-item__attr-value">
                                {{ getCategory(item.descr.category) }}
                            </span>
                        </div>
                        <div
                            v-if="item.descr.price"
                            class="entity-item__attr"
                        >
                            <span class="entity-item__attr-label">
                                Оценка:
                            </span>
                            <span class="entity-item__attr-value">
                                {{ item.descr.price | price }}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    v-if="item.controllable && controls.length"
                    class="entity-item__foot entity-item__panel"
                >
                    <div
                        v-for="control in controls"
                        class="entity-item__panel-item"
                    >
                        <a
                            href="#"
                            class="a"
                            :class="control.color ? 'a--' + control.color : ''"
                            @click.prevent="callControl(control.event, idx)"
                        >
                            {{ control.label }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            class="entity-list__empty"
        >
            <slot name="empty">
                Список пуст
            </slot>
        </div>
    </div>
</template>

<script>
    import _find from 'lodash/find';
    import { jewels } from '@/services';

    export default {
        props: {
            items: {
                type: Array,
                default() { return []; }
            },
            controls: {
                type: Array,
                default() { return []; }
            }
        },
        data() {
            return {
                types: [],
                metals: [],
                standards: [],
                categories: []
            };
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Загрузка вариантов' });

            let promises = [
                jewels.getTypes(),
                jewels.getMetals(),
                jewels.getStandards(),
                jewels.getCategories()
            ];

            Promise.all(promises).then((values) => {
                this.types = values[0];
                this.metals = values[1];
                this.standards = values[2];
                this.categories = values[3];
            }).catch((error) => {
                const err = new Error('Не удалось загрузить варианты');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            }).finally(() => {
                this.$store.dispatch('hideLoader');
            });
        },
        methods: {
            getType(code) {
                let item = _find(this.types, { code });
                return item ? item.name : '';
            },
            getMetal(code) {
                let item = _find(this.metals, { code });
                return item ? item.name : '';
            },
            getStandard(metalCode, code) {
                let item = _find(this.standards, { metal_code: metalCode, code });
                return item ? item.name : '';
            },
            getCategory(code) {
                let item = _find(this.categories, { code });
                return item ? item.name : '';
            },
            callControl(event, idx) {
                this.$emit('control', event, idx);
                this.$emit('control:' + event, idx);
            }
        }
    };
</script>
