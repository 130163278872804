<script>
    import abstractForm from './abstract-form.vue';

    export default {
        extends: abstractForm,
        props: {
            pawnSum: {
                type: Number,
                required: true
            }
        },
        data() {
            const fields = [
                { name: 'pawn_sum', type: 'number', label: 'Доступная сумма, руб.', disabled: true, default: this.pawnSum },
                { name: 'credit_sum', type: 'number', label: 'Сумма к выдаче, руб.', required: true, default: this.pawnSum },
                { name: 'cash_sum', type: 'number', label: 'Выдать наличными, руб.', required: true, default: this.pawnSum },
                { name: 'card_sum', type: 'number', label: 'Выдать на карту, руб.', required: false, disabled: true, default: 0 },
                { name: 'card_number', type: 'mask', label: 'Номер карты', required: false, disabled: true, mask: '9999 9999 9999 9999' }
            ];

            const checks = {
                credit_sum: {
                    required: true,
                    func: (value) => {
                        if (value > this.pawnSum) {
                            return 'Не может быть больше доступной суммы';
                        }
                        if (value <= 0) {
                            return 'Должна быть больше нуля';
                        }
                        return null;
                    }
                },
                cash_sum: {
                    required: true,
                    func: (value) => {
                        if (value > this.val.credit_sum) {
                            return 'Не может быть больше суммы к выдаче';
                        }
                        if (value < 0) {
                            return 'Не может быть отрицательной';
                        }
                        return null;
                    }
                },
                card_sum: {
                    required: false,
                    func: (value) => {
                        if (value !== this.val.credit_sum - this.val.cash_sum) {
                            return 'Не соответствует сумме к выдаче';
                        }
                        if (value < 0) {
                            return 'Не может быть отрицательной';
                        }
                        return null;
                    }
                },
                card_number: {
                    required: true,
                    func: (value) => {
                        if (!/^(\d\d\d\d)\s(\d\d\d\d)\s(\d\d\d\d)\s(\d\d\d\d)$/.test(value)) {
                            return 'Неверный формат номера карты: xxxx xxxx xxxx xxxx';
                        }
                        return null;
                    }
                }
            };

            let data = {
                fields,
                checks,
                inline: true
            };
            return data;
        },
        computed: {
            needCard() {
                return this.val.credit_sum > this.val.cash_sum;
            }
        },
        watch: {
            'val.credit_sum': function() {
                this.checkCard();
                this.fitCashSum();
            },
            'val.cash_sum': function() {
                this.checkCard();
                this.fitCardSum();
            },
            'val.card_sum': function() {
                this.fitCashSum();
            },
            'val.card_number': function() {
                this.change('card_number');
            }
        },
        created() {
            this.checkCard();
            this.fitCardSum();
        },
        methods: {
            checkCard() {
                this.checks.card_sum.required = this.needCard;
                this.checks.card_number.required = this.needCard;

                this.fieldRel.card_sum.required = this.needCard;
                this.fieldRel.card_number.required = this.needCard;
                this.fieldRel.card_sum.disabled = !this.needCard;
                this.fieldRel.card_number.disabled = !this.needCard;
            },
            fitCardSum() {
                if (this.needCard) {
                    this.val.card_sum = this.val.credit_sum - this.val.cash_sum;
                } else {
                    this.val.card_sum = 0;
                }
            },
            fitCashSum() {
                this.val.cash_sum = this.val.credit_sum - this.val.card_sum;
            }
        }
    };
</script>
