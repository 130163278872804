<template>
    <div class="main__block">
        <div class="main__body">
            <h2 class="h2">
                Проверьте данные
            </h2>

            <div class="main__section">
                <h3 class="h3">
                    Итоговое описание
                </h3>
                <div class="text">
                    <pre>{{ content }}</pre>
                </div>
            </div>

            <div class="main__section">
                <h3 class="h3">
                    Внешний вид
                    <a
                        href="#"
                        class="main__goto"
                        @click.prevent="goTo('info')"
                    />
                </h3>
                <div class="info">
                    <value
                        type="text"
                        :inline="true"
                        label="Тип изделия"
                        :value="info_type"
                        class="info__field"
                    />
                    <value
                        type="text"
                        :inline="true"
                        label="Номинальная проба"
                        :value="info_standard"
                        class="info__field"
                    />
                    <value
                        type="textarea"
                        :inline="true"
                        label="Описание"
                        :value="item.info.comment"
                        class="info__field"
                    />
                    <value
                        type="text"
                        :inline="true"
                        label="Именник"
                        :value="info_stamp"
                        class="info__field"
                    />
                    <value
                        type="text"
                        :inline="true"
                        label="Клеймо"
                        :value="info_indent"
                        class="info__field"
                    />
                    <value
                        type="boolean"
                        :inline="true"
                        label="Клеймо подлинное"
                        :value="item.info.is_approved"
                        class="info__field"
                    />
                </div>
            </div>

            <div
                v-if="prop_settings.fields.length"
                class="main__section"
            >
                <h3 class="h3">
                    Атрибуты и дефекты
                    <a
                        href="#"
                        class="main__goto"
                        @click.prevent="goTo('prop')"
                    />
                </h3>
                <custom-info
                    :values="prop"
                    :settings="prop_settings"
                />
            </div>

            <div
                v-if="item.info._inserts_avail"
                class="main__section"
            >
                <h3 class="h3">
                    Вставки
                    <a
                        href="#"
                        class="main__goto"
                        @click.prevent="goTo('inserts')"
                    />
                </h3>
                <insert-list :items="item.inserts" />
            </div>

            <div class="main__section">
                <h3 class="h3">
                    Экспертная оценка
                    <a
                        href="#"
                        class="main__goto"
                        @click.prevent="goTo('descr')"
                    />
                </h3>
                <div class="info">
                    <value
                        type="text"
                        :inline="true"
                        label="Определенная проба"
                        :value="descr_standard"
                        class="info__field"
                    />
                    <value
                        type="number"
                        :inline="true"
                        label="Общий вес, гр."
                        :value="item.descr.total_weight"
                        :precision="2"
                        class="info__field"
                    />
                    <value
                        type="number"
                        :inline="true"
                        label="Чистый вес, гр."
                        :value="item.descr.weight"
                        :precision="2"
                        class="info__field"
                    />
                    <value
                        type="text"
                        :inline="true"
                        label="Категория оценки"
                        :value="descr_category"
                        class="info__field"
                    />
                    <value
                        type="number"
                        :inline="true"
                        label="Оценка, руб."
                        :value="item.descr.price"
                        class="info__field"
                    />
                </div>
            </div>
        </div>

        <div class="main__foot main__bar">
            <button
                class="button button--white main__cancel"
                @click="prev"
            >
                Вернуться назад
            </button>
            <button
                class="button button--green main__submit"
                @click="next"
            >
                Продолжить заполнение
            </button>
        </div>
    </div>
</template>

<script>
    import _find from 'lodash/find';
    import _map from 'lodash/map';
    import { jewels } from '@/services';
    import customInfo from '@/components/custom-info.vue';
    import insertList from '@/components/insert-list.vue';
    import value from '@/components/value.vue';

    export default {
        components: {
            customInfo,
            insertList,
            value
        },
        props: {
            item: Object
        },
        data() {
            return {
                groups: [],
                types: [],
                metals: [],
                standards: [],
                stamp_types: [],
                indent_types: [],
                attrs: [],
                defects: [],
                inserts: [],
                categories: []
            };
        },
        computed: {
            content() {
                let text = `${ this.info_type }`;
                if (this.item.info.stamp_symbol) {
                    text += ` ${ this.item.info.stamp_symbol }`;
                    if (!this.item.info.is_approved) {
                        text += ' сомн.'
                    }
                }
                text += ` ${ this.item.info.standard }`;
                for (let insert of this.item.inserts) {
                    let item = _find(this.inserts, { code: insert.insert });
                    if (item) {
                        text += ` ${ insert.count } ${ item.name }`;
                    }
                }
                for (let defect of this.defects) {
                    if (this.item.prop.defects[ defect.code ]) {
                        text += ` ${ defect.name }`;
                    }
                }
                if (this.item.info.comment) {
                    text += `\n${ this.item.info.comment }`;
                }
                return text;
            },
            info_type() {
                let type = _find(this.types, { code: this.item.info.type });
                if (type) {
                    return type.name;
                }
                return '';
            },
            info_standard() {
                let metal = _find(this.metals, { code: this.item.info.metal }),
                    standard = _find(this.standards, { metal_code: this.item.info.metal, code: this.item.info.standard });
                if (metal && standard) {
                    return metal.name + ' / ' + standard.name;
                }
                return '';
            },
            info_stamp() {
                let type = _find(this.stamp_types, { code: this.item.info.stamp_type });
                if (type) {
                    let stamp = type.name;
                    if (this.item.info.stamp_symbol) {
                        stamp += ' / ' + this.item.info.stamp_symbol;
                    }
                    return stamp;
                }
                return this.item.info.stamp_symbol;
            },
            info_indent() {
                let type = _find(this.indent_types, { code: this.item.info.indent_type });
                if (type) {
                    let indent = type.name;
                    if (this.item.info.indent_symbol) {
                        indent += ' / ' + this.item.info.indent_symbol;
                    }
                    return indent;
                }
                return this.item.info.indent_symbol;
            },
            prop_settings() {
                let fields = [],
                    fieldsets = [
                        { header: 'Атрибуты', names: [] },
                        { header: 'Дефекты', names: [] }
                    ];

                for (let attr of this.attrs) {
                    let field = {
                        name: attr.code,
                        label: attr.name,
                        type: attr.field
                    };

                    if (attr.options) {
                        field.options = _map(attr.options, (el) => {
                            return { name: el.name, value: el.code };
                        });
                    }

                    fields.push(field);
                    fieldsets[0].names.push(attr.code);
                }

                for (let defect of this.defects) {
                    let field = {
                        name: defect.code,
                        label: defect.name,
                        type: 'boolean'
                    };

                    fields.push(field);
                    fieldsets[1].names.push(defect.code);
                }

                return {
                    fields,
                    fieldsets
                };
            },
            prop() {
                let data = {};

                if (this.item.prop) {
                    for (let attr of this.attrs) {
                        if (this.item.prop.attrs && this.item.prop.attrs[ attr.code ] !== undefined) {
                            data[ attr.code ] = this.item.prop.attrs[ attr.code ];
                        }
                    }
                    for (let defect of this.defects) {
                        if (this.item.prop.defects && this.item.prop.defects[ defect.code ] !== undefined) {
                            data[ defect.code ] = this.item.prop.defects[ defect.code ];
                        }
                    }
                }

                return data;
            },
            descr_standard() {
                let metal = _find(this.metals, { code: this.item.descr.metal }),
                    standard = _find(this.standards, { metal_code: this.item.descr.metal, code: this.item.descr.standard });
                if (metal && standard) {
                    return metal.name + ' / ' + standard.name;
                }
                return '';
            },
            descr_category() {
                let category = _find(this.categories, { code: this.item.descr.category });
                if (category) {
                    return category.name;
                }
                return '';
            }
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Загрузка вариантов' });

            let promises = [
                jewels.getGroups(),
                jewels.getTypes(),
                jewels.getMetals(),
                jewels.getStandards(),
                jewels.getStampTypes(),
                jewels.getIndentTypes(),
                jewels.getAttrs({ type_code: this.item.info.type }),
                jewels.getDefects({ type_code: this.item.info.type }),
                jewels.getInserts(),
                jewels.getCategories()
            ];

            Promise.all(promises).then((values) => {
                this.groups = values[0];
                this.types = values[1];
                this.metals = values[2];
                this.standards = values[3];
                this.stamp_types = values[4];
                this.indent_types = values[5];
                this.attrs = values[6];
                this.defects = values[7];
                this.inserts = values[8];
                this.categories = values[9];
            }).catch((error) => {
                const err = new Error('Не удалось загрузить варианты');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            }).finally(() => {
                this.$store.dispatch('hideLoader');
            });
        },
        methods: {
            goTo(stage) {
                this.$emit('goto', stage);
            },
            prev() {
                this.$emit('prev');
            },
            next() {
                this.$emit('next');
            }
        }
    };
</script>
