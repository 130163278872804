<template>
    <div class="main__block">
        <div class="main__body">
            <h2 class="h2">
                Опишите атрибуты и дефекты
            </h2>
            <custom-form
                v-if="settings.fields.length"
                ref="form"
                :values="val"
                :settings="settings"
                @change="checkValid"
            />
        </div>

        <div class="main__foot main__bar">
            <button
                class="main__cancel button button--white"
                @click="prev"
            >
                Вернуться назад
            </button>
            <button
                :disabled="!isValid"
                class="main__submit button button--green"
                @click="next"
            >
                Продолжить заполнение
            </button>
        </div>
    </div>
</template>

<script>
    import _map from 'lodash/map';
    import { jewels } from '@/services';
    import customForm from '@/components/custom-form.vue';

    export default {
        components: {
            customForm
        },
        props: {
            item: Object
        },
        data() {
            return {
                isValid: false,
                attrs: [],
                defects: []
            };
        },
        computed: {
            settings() {
                let fields = [],
                    fieldsets = [
                        { header: 'Атрибуты', names: [] },
                        { header: 'Дефекты', names: [] }
                    ];

                for (let attr of this.attrs) {
                    let field = {
                        name: attr.code,
                        label: attr.name,
                        type: attr.field,
                        required: attr.is_required,
                        checks: {}
                    };

                    if (attr.options) {
                        field.options = _map(attr.options, (el) => {
                            return { name: el.name, value: el.code };
                        });
                    }
                    if (attr.is_required) {
                        field.required = true;
                        field.checks.required = true;
                    }
                    if (attr.type === 'number') {
                        field.checks.number = true;
                    }

                    fields.push(field);
                    fieldsets[0].names.push(attr.code);
                }

                for (let defect of this.defects) {
                    let field = {
                        name: defect.code,
                        label: defect.name,
                        type: 'boolean'
                    };

                    fields.push(field);
                    fieldsets[1].names.push(defect.code);
                }

                return {
                    fields,
                    fieldsets
                };
            },
            val() {
                let data = {};

                if (this.item.prop) {
                    for (let attr of this.attrs) {
                        if (this.item.prop.attrs && this.item.prop.attrs[ attr.code ] !== undefined) {
                            data[ attr.code ] = this.item.prop.attrs[ attr.code ];
                        }
                    }
                    for (let defect of this.defects) {
                        if (this.item.prop.defects && this.item.prop.defects[ defect.code ] !== undefined) {
                            data[ defect.code ] = this.item.prop.defects[ defect.code ];
                        }
                    }
                }

                return data;
            }
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Загрузка вариантов' });

            let promises = [
                jewels.getAttrs({ type_code: this.item.info.type }),
                jewels.getDefects({ type_code: this.item.info.type })
            ];

            Promise.all(promises).then((values) => {
                this.attrs = values[0];
                this.defects = values[1];

                this.$nextTick(() => {
                    this.checkValid();
                });
            }).catch((error) => {
                const err = new Error('Не удалось загрузить варианты');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            }).finally(() => {
                this.$store.dispatch('hideLoader');
            });
        },
        methods: {
            serialize() {
                let data = {},
                    attrs = {},
                    defects = {};

                if (this.$refs.form) {
                    data = this.$refs.form.serialize();
                }
                    
                for (let attr of this.attrs) {
                    attrs[ attr.code ] = data[ attr.code ];
                }
                for (let defect of this.defects) {
                    defects[ defect.code ] = data[ defect.code ];
                }

                return {
                    attrs,
                    defects
                };
            },
            checkValid() {
                if (this.$refs.form) {
                    this.isValid = this.$refs.form.validate();
                } else {
                    this.isValid = true;
                }
            },
            prev() {
                this.$emit('prev', this.serialize());
            },
            next() {
                this.$emit('next', this.serialize());
            }
        }
    };
</script>
